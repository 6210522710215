import React, { useEffect, useState } from 'react';

import {
  faEyeSlash, faEye, faEnvelope, faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import styles from './Input.module.css';
import './styles.css';
import phoneStyles from './phoneStyles';

const {
  dropdownStyle,
  containerStyle,
  inputStyle,
  buttonStyle,
} = phoneStyles;

export const Input = React.forwardRef(
  ({
    errorMsg: errorMsgProp = '',
    className = '',
    errorStyle = {},
    name = '',
    register = () => {},
    prefix = null,
    label = '',
    subLabel = '',
    style,
    type = 'text',
    autoComplete = 'true',
    textArea = false,
    placeholder,
    version = null,
    isReadOnly = false,
    // dropRight = false,
    inputRef = null,
    onBlur = null,
    dataTest = '',
    ...rest
  }: any) => {
    const [errorMsg, setErrorMsg] = useState(errorMsgProp);
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    useEffect(() => {
      setErrorMsg(errorMsgProp);
    }, [errorMsgProp]);

    // TODO: need to set ref and onBlur in the Controller around the project and then change to normal props:
    const refProp = inputRef ? { ref: inputRef } : {};
    const onBlurProp = onBlur ? { onBlur } : {};

    return (
      <div style={{ width: '100%' }} data-test={dataTest}>
        {label && <div className="form__input_label">{label}</div>}
        {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
        <div
          className={`form__input d-flex flex-column ${className}
          ${textArea ? 'text-area-input' : ''}
          ${rest && rest.disabled ? ' disabled' : ''}
          ${errorMsg && 'invalid'}`}
        >
          <div className="d-flex">{prefix}</div>
          {textArea ? (
            <textarea
              placeholder={placeholder}
              {...register(name)}
              {...rest}
              readOnly={isReadOnly}
              style={{
                ...style,
                resize: 'none',
                fontSize: 16,
                outline: 'none',
                border: 'none',
                padding: '12px 0',
                width: '100%',
                backgroundColor: 'transparent',
              }}
              rows={2}
              cols={50}
            />
          ) : (
            <>
              {type === 'phone'
                ? (
                  <PhoneInput
                    {...register(name)}
                    {...rest}
                    country="us"
                    regions={['america', 'europe', 'oceania', 'asia']}
                    preferredCountries={['au', 'ca', 'cn', 'nz', 'gb', 'us', 'ua']}
                    dropdownStyle={dropdownStyle}
                    containerStyle={containerStyle}
                    inputStyle={inputStyle}
                    buttonStyle={buttonStyle}
                    disabled={rest.disabled}
                    inputProps={{
                      readOnly: isReadOnly,
                    }}
                    onChange={(value) => rest.onChange(value || '')}
                    {...refProp}
                    {...onBlurProp}
                    name={name}
                    placeholder={placeholder}
                  />
                )
                : (
                  <>
                    {
                  version === 'email' ? (
                    <FontAwesomeIcon className={cn(styles.translucentOpacity, styles.icon)} icon={faEnvelope} />
                  )
                    : version === 'password' ? (
                      <FontAwesomeIcon className={cn(styles.translucentOpacity, styles.icon)} icon={faLock} />
                    )
                      : null
                }
                    <input
                      {...register(name)}
                      {...rest}
                      readOnly={isReadOnly}
                      type={passwordShown ? 'text' : type || 'text'}
                      // TODO: need to set ref and onBlur in the Controller around the project and then change to normal props:
                      {...refProp}
                      {...onBlurProp}
                      style={style}
                      name={name}
                      autoComplete={autoComplete}
                      placeholder={placeholder}
                    />
                  </>
                )}
              {version === 'password' ? (
                <button
                  type="button"
                  className={cn(styles.showHideButton)}
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon className={styles.translucentOpacity} icon={passwordShown ? faEye : faEyeSlash} />
                </button>
              ) : null}
            </>
          )}
          {errorMsg && (
          <span
            className={cn(styles.errorMessage, errorStyle)}
          >
            {errorMsg}
          </span>
          )}
        </div>
      </div>
    );
  },
);

Input.displayName = 'Input';
